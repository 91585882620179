import '@mdi/font/css/materialdesignicons.css';
import 'tinymce/skins/ui/oxide/skin.css';
import 'tinymce/skins/ui/oxide/content.css';
import 'tinymce/skins/content/default/content.css';
import 'tinymce/tinymce';
import 'tinymce/themes/silver';
import 'tinymce/icons/default';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/link';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/wordcount';
import Vue from 'vue';
import i18n from '@/localization/i18n';
import App from '@/App.vue';
import vuetify from '@/plugins/vuetify';
import store from '@/store';
import router from '@/router';
import { createMapInstance } from '@/main/mapInstance';
import VueSocialSharing from 'vue-social-sharing';
import VueCookieAcceptDecline from 'vue-cookie-accept-decline';

import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css';

Vue.prototype.$map = createMapInstance();
Vue.use(VueSocialSharing);
Vue.use(VueCookieAcceptDecline);

new Vue({
  i18n,
  store,
  router,
  //@ts-ignore
  vuetify,
  render: h => h(App)
}).$mount('#app');
