<template>
  <v-app class="app">
    <LoadingScreen :is-loading="$store.state.loading" />
    <OverlayScroller :switch="overlayTrigger" :html="overlayHtml" />

    <v-dialog
      persistent
      v-model="termsAccept"
      :width="$vuetify.breakpoint.xs ? '100%' : '80%'"
      max-width="400px"
      :class="$vuetify.breakpoint.xs ? 'ma-0' : 'ma-2'"
    >
      <v-card>
        <v-row no-gutters>
          <v-col cols="12" class="pa-5">
            {{ $t('dialog.newterms1') }}
            <a @click="showOverlay('terms')">{{ $t('dialog.newterms2') }}</a>
            {{ $t('dialog.newterms3') }}
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="6" class="pa-5">
            <v-btn :to="'/' + this.$route.params.lang + '/logout'" block color="grey darken-1" dark>{{
              $t('button.decline')
            }}</v-btn>
          </v-col>
          <v-col cols="6" class="pa-5">
            <v-btn @click="acceptTerms" block color="rgb(0, 90, 154)" dark>{{ $t('button.accept') }}</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialogEdit"
      :width="$vuetify.breakpoint.xs ? '100%' : '80%'"
      max-width="900px"
      :class="$vuetify.breakpoint.xs ? 'ma-0' : 'ma-2'"
    >
      <benutzer-formular
        v-if="dialogEdit"
        :benutzer-id="$store.state.userdata.id"
        @editorClose="dialogClose"
        :type="accountNotComplete ? 'verify' : ''"
      />
    </v-dialog>
    <Confirm ref="confirm" />

    <v-overlay :value="!cookiesAccepted" z-index="500" />
    <VueCookieAcceptDecline
      style="z-index: 501"
      @clicked-accept="cookiesAccepted = true"
      @status="cookieStatus"
      elementId="cookies"
      position="bottom"
      type="bar"
      disableDecline
    >
      <template>
        <div slot="message">
          {{ $t('label.cookies') }}
          <br />
          <v-btn class="px-0 mt-2" text small @click="showOverlay('privacy')">
            {{ $t('navigation.privacy') }}
          </v-btn>
        </div>
      </template>
      <template>
        <div slot="acceptContent">{{ $t('label.accept') }}</div>
      </template>
    </VueCookieAcceptDecline>
    <v-app-bar
      v-if="!isPanel"
      class="myAppBar pr-4"
      height="60"
      color="rgb(0,90,154)"
      elevation="0"
      app
      short
      clipped-left
    >
      <div class="whiteLogoParking">
        <img
          @click="$router.push({ path: '/' + $route.params.lang + '/home' })"
          width="60"
          height="60"
          src="/img/logo-ecoplus.png"
          alt="ecoplus Logo"
        />
      </div>
      <div v-if="isAdmin === false && !$vuetify.breakpoint.xs" class="claim">
        <div>{{ this.$t('navigation.ecoplus1') }}</div>
        <div>{{ this.$t('navigation.ecoplus2') }}</div>
      </div>
      <div v-if="isAdmin === true" class="claim pa-3">
        <v-icon dark x-large>mdi-cogs</v-icon>
        <v-btn
          v-if="isAdmin === true"
          color="rgba(0,90,154)"
          depressed
          dark
          :to="'/' + this.$route.params.lang + '/home'"
        >
          <v-icon>mdi-home</v-icon>
        </v-btn>
      </div>
      <a href="https://www.noe.gv.at/" target="_blank" class="claimimage"
        ><img v-if="isAdmin === false" src="/img/noe-logo-400.png" alt="Bildmarke NÖ"
      /></a>
      <v-spacer />
      <div class="shownAboveMobile">
        <v-btn
          v-if="isAdmin === false"
          color="rgba(0,90,154)"
          depressed
          dark
          :to="'/' + this.$route.params.lang + '/home'"
        >
          {{ this.$t('navigation.home') }}
        </v-btn>
        <v-btn
          v-if="isAdmin === false"
          color="rgba(0,90,154)"
          depressed
          dark
          @click="restoreOriginalExtent()"
          :to="'/' + this.$route.params.lang + '/standortsuche'"
        >
          {{ this.$t('navigation.standortsuche') }}
        </v-btn>
        <v-btn
          v-if="isAdmin === false"
          color="rgba(0,90,154)"
          depressed
          dark
          :to="'/' + this.$route.params.lang + '/foerderkompass'"
        >
          {{ this.$t('navigation.advancementcompass') }}
        </v-btn>
        <v-btn
          v-if="isAdmin === false"
          color="rgba(0,90,154)"
          depressed
          dark
          :to="'/' + this.$route.params.lang + '/wissen'"
        >
          {{ this.$t('navigation.knowhow') }}
        </v-btn>

        <v-menu
          offset-y
          v-if="
            !this.$route.query.barrierefrei && isAdmin === false && $store.state.userdata && $store.state.userdata.id
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="rgba(0,90,154)" depressed dark class="mycompass">
              <v-icon small class="mr-2"> mdi-account </v-icon>
              {{ $t('navigation.mycompass') }}
            </v-btn>
          </template>
          <v-list class="backBlue">
            <v-btn @click="dialogEdit = !dialogEdit" color="rgba(0,90,154)" depressed dark block>
              <v-icon small class="mr-2"> mdi-account-cog </v-icon>
              {{ this.$t('navigation.account') }}
            </v-btn>
            <v-btn color="rgba(0,90,154)" depressed dark block :to="'/' + this.$route.params.lang + '/meineimmobilien'">
              <v-icon small class="mr-2"> mdi-home-account </v-icon>
              {{ this.$t('navigation.myproperties') }}
            </v-btn>
            <v-btn
              color="rgba(0,90,154)"
              v-if="$store.state.userdata && $store.state.userdata.id"
              depressed
              dark
              block
              @click="showOverlay('contactEcoplus')"
            >
              <v-icon small class="mr-2"> mdi-human-greeting-variant </v-icon>
              {{ this.$t('navigation.contactEcoplus') }}
            </v-btn>
            <v-btn
              color="rgba(0,90,154)"
              depressed
              dark
              block
              :to="'/' + this.$route.params.lang + '/admin'"
              v-if="isAdmin === false && $store.state.userdata && $store.state.userdata.userlevel > 4"
            >
              <v-icon small class="mr-2"> mdi-cogs </v-icon>
              {{ this.$t('menu.administration') }}
            </v-btn>

            <v-btn :to="'/' + this.$route.params.lang + '/logout'" color="rgba(0,90,154)" depressed dark block>
              <v-icon small class="mr-2"> mdi-logout </v-icon>
              {{ this.$t('button.logout') }}
            </v-btn>
          </v-list>
        </v-menu>

        <v-btn
          :to="'/' + this.$route.params.lang + '/login'"
          v-if="
            !this.$route.query.barrierefrei &&
            isAdmin === false &&
            (!$store.state.userdata || !$store.state.userdata.id)
          "
          color="rgba(0,90,154)"
          depressed
          dark
          class="mycompass"
        >
          <v-icon small class="mr-2"> mdi-account </v-icon>
          {{ this.$t('button.login') }}
        </v-btn>
      </div>
      <v-select
        v-if="isAdmin === false"
        dark
        dense
        hide-details
        :items="langkeys"
        v-model="currentLanguage"
        class="langSelect"
        @change="langChoosen($event)"
      />
      <v-btn
        v-if="isAdmin === true"
        color="rgba(0,90,154)"
        depressed
        dark
        @click="restoreOriginalExtent()"
        :to="'/' + this.$route.params.lang + '/admin/liste'"
      >
        Objektverwaltung
      </v-btn>
      <v-btn
        v-if="isAdmin === true"
        color="rgba(0,90,154)"
        depressed
        dark
        :to="'/' + this.$route.params.lang + '/admin/foerdermatrix'"
      >
        Fördermatrix
      </v-btn>
      <v-btn
        v-if="isAdmin === true && !onlyEcoPlus"
        color="rgba(0,90,154)"
        depressed
        dark
        :to="'/' + this.$route.params.lang + '/admin/benutzer'"
      >
        Benutzerverwaltung
      </v-btn>
      <v-btn
        v-if="isAdmin === true && !onlyEcoPlus"
        color="rgba(0,90,154)"
        depressed
        dark
        :to="'/' + this.$route.params.lang + '/admin/kampagnen'"
      >
        Kampagnen
      </v-btn>
      <v-btn
        v-if="isAdmin === true && !onlyEcoPlus"
        color="rgba(0,90,154)"
        depressed
        dark
        :to="'/' + this.$route.params.lang + '/admin/glossar'"
      >
        Glossar
      </v-btn>
      <v-btn
        v-if="isAdmin === true && !onlyEcoPlus"
        color="rgba(0,90,154)"
        depressed
        dark
        :to="'/' + this.$route.params.lang + '/admin/statistik'"
      >
        Statistik
      </v-btn>
      <v-btn
        v-if="isAdmin === true"
        color="rgba(0,90,154)"
        depressed
        dark
        :to="'/' + this.$route.params.lang + '/logout'"
      >
        <v-icon>mdi-power</v-icon>
      </v-btn>

      <v-app-bar-nav-icon dark class="hiddenAboveMobile" aria-label="Navigation" @click.stop="drawer = !drawer" />
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      class="navigationDrawer hiddenAboveMobile"
      absolute
      right
      clipped
      width="280px"
      height="auto"
      color="rgb(0, 90, 154)"
    >
      <v-btn
        v-if="isAdmin === false"
        color="rgba(0,90,154)"
        depressed
        dark
        block
        class="mt-15 mobile"
        :to="'/' + this.$route.params.lang + '/home'"
      >
        {{ this.$t('navigation.home') }}
      </v-btn>
      <v-btn
        v-if="isAdmin === false"
        color="rgba(0,90,154)"
        depressed
        dark
        block
        class="mobile"
        @click="restoreOriginalExtent()"
        :to="'/' + this.$route.params.lang + '/standortsuche'"
      >
        {{ this.$t('navigation.standortsuche') }}
      </v-btn>
      <v-btn
        v-if="isAdmin === false"
        color="rgba(0,90,154)"
        depressed
        dark
        block
        class="mobile"
        :to="'/' + this.$route.params.lang + '/foerderkompass'"
      >
        {{ this.$t('navigation.advancementcompass') }}
      </v-btn>
      <v-btn
        v-if="isAdmin === false"
        color="rgba(0,90,154)"
        depressed
        dark
        block
        class="mobile"
        :to="'/' + this.$route.params.lang + '/wissen'"
      >
        {{ this.$t('navigation.knowhow') }}
      </v-btn>

      <div
        class="myAccountMobile"
        :class="{
          opened: isAdmin === false && $store.state.userdata && $store.state.userdata.id
        }"
      >
        <v-btn @click="dialogEdit = !dialogEdit" color="rgba(0,90,154)" depressed dark block class="mobile">
          <v-icon small class="mr-2"> mdi-account-cog </v-icon>
          {{ this.$t('navigation.account') }}
        </v-btn>
        <v-btn
          color="rgba(0,90,154)"
          depressed
          dark
          block
          class="mobile"
          :to="'/' + this.$route.params.lang + '/meineimmobilien'"
        >
          <v-icon small class="mr-2"> mdi-home-account </v-icon>
          {{ this.$t('navigation.myproperties') }}
        </v-btn>
        <v-btn
          color="rgba(0,90,154)"
          v-if="$store.state.userdata && $store.state.userdata.id"
          depressed
          dark
          block
          class="mobile"
          @click="showOverlay('contactEcoplus')"
        >
          <v-icon small class="mr-2"> mdi-human-greeting-variant </v-icon>
          {{ this.$t('navigation.contactEcoplus') }}
        </v-btn>
        <v-btn
          color="rgba(0,90,154)"
          depressed
          dark
          block
          class="mobile"
          :to="'/' + this.$route.params.lang + '/admin'"
          v-if="isAdmin === false && $store.state.userdata && $store.state.userdata.userlevel > 4"
        >
          <v-icon small class="mr-2"> mdi-cogs </v-icon>
          {{ this.$t('menu.administration') }}
        </v-btn>

        <v-btn
          :to="'/' + this.$route.params.lang + '/logout'"
          color="rgba(0,90,154)"
          depressed
          dark
          block
          class="mobile"
        >
          <v-icon small class="mr-2"> mdi-logout </v-icon>
          {{ this.$t('button.logout') }}
        </v-btn>
      </div>

      <v-btn
        :to="'/' + this.$route.params.lang + '/login'"
        v-if="isAdmin === false && (!$store.state.userdata || !$store.state.userdata.id)"
        color="rgba(0,90,154)"
        depressed
        dark
        block
        class="mycompass mobile"
      >
        <v-icon small class="mr-2"> mdi-account </v-icon>
        {{ this.$t('button.login') }}
      </v-btn>

      <div class="myAccountMobile" v-if="!$vuetify.breakpoint.smAndUp"></div>
      <v-btn
        v-if="!$vuetify.breakpoint.xsAndUp"
        small
        color="rgba(0,90,154)"
        depressed
        dark
        block
        class="mobile"
        @click="showOverlay('terms')"
      >
        {{ this.$t('navigation.terms') }}
      </v-btn>
      <v-btn
        v-if="!$vuetify.breakpoint.xsAndUp"
        small
        color="rgba(0,90,154)"
        depressed
        dark
        block
        class="mobile"
        @click="showOverlay('privacy')"
      >
        {{ this.$t('navigation.privacy') }}
      </v-btn>

      <v-btn
        v-if="!$vuetify.breakpoint.xsAndUp"
        small
        color="rgba(0,90,154)"
        depressed
        dark
        block
        class="mobile"
        :to="'/' + this.$route.params.lang + '/glossar'"
      >
        {{ this.$t('navigation.glossary') }}
      </v-btn>
    </v-navigation-drawer>

    <v-main class="mainPage" :class="{ admin: isAdmin }">
      <router-view :is-loading="$store.state.isLoading" />
    </v-main>
    <v-footer v-if="!isPanel && isAdmin === false" color="rgb(0,90,154)" elevation="0" dark padless>
      <v-row no-gutters>
        <v-col class="pl-1" :cols="$vuetify.breakpoint.smAndUp ? 9 : 6">
          <v-btn small color="rgba(0,90,154)" depressed dark @click="showOverlay('imprint')">
            {{ this.$t('navigation.contact') }} /
            {{ this.$t('navigation.imprint') }}
          </v-btn>
          <v-btn
            v-if="!$vuetify.breakpoint.smAndDown"
            small
            color="rgba(0,90,154)"
            depressed
            dark
            @click="showOverlay('terms')"
          >
            {{ this.$t('navigation.terms') }}
          </v-btn>
          <v-btn
            v-if="!$vuetify.breakpoint.smAndDown"
            small
            color="rgba(0,90,154)"
            depressed
            dark
            @click="showOverlay('privacy')"
          >
            {{ this.$t('navigation.privacy') }}
          </v-btn>
          <v-btn
            v-if="!$vuetify.breakpoint.smAndDown"
            small
            color="rgba(0,90,154)"
            depressed
            dark
            :to="'/' + this.$route.params.lang + '/glossar'"
          >
            {{ this.$t('navigation.glossary') }}
          </v-btn>
        </v-col>
        <v-col class="pr-1" align="right" :cols="$vuetify.breakpoint.smAndUp ? 3 : 6">
          <v-btn small color="rgba(0,90,154)" depressed dark @click="switchBarrierefrei()">
            {{
              this.$route.query.barrierefrei
                ? this.$t('navigation.leaveBarrierfree')
                : this.$t('navigation.barrierfree')
            }}
          </v-btn>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import Confirm from '@/components/misc/Confirm.vue';
import OverlayScroller from '@/components/misc/OverlayScroller.vue';
import LoadingScreen from './components/misc/Loading.vue';
import { bind as bindObjektSource } from '@/main/objektSource';
import { decodeUriValueForKey, defaultFilterValues } from '@/store/defaultValues';
import benutzerFormular from '@/components/forms/benutzerform.vue';
import { noeExtent } from './utils/shift';
// @ts-ignore
import VueCookieAcceptDecline from 'vue-cookie-accept-decline';
import { getCookie } from './utils/utils';
import axios from 'axios';

export default {
  name: 'App',
  components: {
    LoadingScreen,
    Confirm,
    benutzerFormular,
    OverlayScroller,
    VueCookieAcceptDecline
  },
  data: () => ({
    overlayTrigger: false,
    overlayHtml: '',
    dialogEdit: false,
    drawer: false,
    loadingElements: {},
    headerItems: null,
    cookiesAccepted: false,
    langkeys: [
      { text: 'DE', value: 'de' },
      { text: 'EN', value: 'en' }
    ],
    currentLanguage: 'de'
  }),
  computed: {
    termsAccept() {
      return this.$store.state.userdata &&
        this.$store.state.userdata.data &&
        this.$store.state.userdata.termsaccepted === false
        ? true
        : false;
    },
    isPanel() {
      return this.$route.path.indexOf('panel') > -1;
    },

    isAdmin() {
      return this.$route.meta.requiresAuth;
    },
    onlyEcoPlus() {
      return (
        this.$store.state.userdata && this.$store.state.userdata.userlevel && this.$store.state.userdata.userlevel == 5
      );
    },

    accountNotComplete() {
      return this.$store.state.userdata?.email && this.$store.state.userdata.profil !== 100;
    },
    /**
     * current query params from the store, consisting of zoom/center and filters
     * the only function of this computed prop is to have a single watcher for all filter values
     */
    queryParamsFromStore() {
      const state = this.$store.state;
      return {
        selectedType: state.selectedType,
        selectedFlaeche: state.selectedFlaeche,
        selectedPreis: state.selectedPreis,
        mieteOrKauf: state.mieteOrKauf,
        selectedTag: state.selectedTag,
        ecoplusOnly: state.ecoplusOnly,
        favsOnly: state.favsOnly,
        selectedStatus: state.selectedStatus
      };
    }
  },

  watch: {
    /**
     * this watcher will get activated either when the user accepts the cookies for the
     * first time, or when the component is created and the user accepted cookies in an
     * earlier session
     */
    cookiesAccepted(accepted) {
      if (accepted) {
        (function (w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
          const f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != 'dataLayer' ? '&l=' + l : '';
          // @ts-ignore
          j.async = true;
          // @ts-ignore
          j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
          f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', 'GTM-5D8R89Q');
      }
    },

    '$store.state.contact'(value) {
      if (value) {
        const contactShown = getCookie('contactShown');
        if (contactShown !== 'true') {
          this.showOverlay('contactEcoplus');
        }
      }
    },
    accountNotComplete(value) {
      if (value) {
        this.dialogEdit = true;
      }
    },
    '$route.params.lang'(value) {
      this.currentLanguage = value;
    },
    '$route.query'() {
      // update the store when the route changes
      // handles back button
      const query = this.$router.currentRoute.query;
      if (this.$router.currentRoute.query.activated && this.$router.currentRoute.query.activated == '1') {
        this.$root.$confirm({
          title: `${this.$t('label.activatedHeader')}`,
          message: `${this.$t('label.activatedText')}`,
          okonly: true,
          appearance: 'success'
        });
      }

      for (const key in defaultFilterValues) {
        // eslint-disable-next-line no-prototype-builtins
        if (query.hasOwnProperty(key)) {
          const value = query[key];
          const decodedValue = decodeUriValueForKey(key, value);
          this.$store.dispatch(key, decodedValue);
        } else if (this.$store.state[key] !== defaultFilterValues[key]) {
          this.$store.dispatch(key, defaultFilterValues[key]);
        }
      }
    },
    queryParamsFromStore(value) {
      const router = this.$router;
      const currentRoute = router.currentRoute;
      const currentQuery = currentRoute.query;
      const newQuery = Object.assign({}, currentQuery);
      for (const key in value) {
        // if the current value in the store is equal to the default value, remove it from the url
        if (value[key] === defaultFilterValues[key]) {
          // https://forum.vuejs.org/t/how-to-remove-one-query-string-from-url/39176/2
          newQuery[key] = undefined;
        } else {
          // else, if the value in the store is not equal to the value in the url query, update the query
          newQuery[key] = encodeURIComponent(value[key]);
        }
      }
      const name = currentRoute.name;
      if (JSON.stringify(newQuery) !== JSON.stringify(currentQuery)) {
        router
          .push({
            name,
            params: currentRoute.params,
            query: newQuery
          })
          .catch(() => {});
      }
    },
    group() {
      this.drawer = false;
    }
  },
  created() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    window.addEventListener('resize', () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });

    bindObjektSource();
    this.$root.$on('loading', data => {
      this.$store.dispatch('loading', true);
      this.loadingElements[data] = true;
    });
    this.$root.$on('loaded', data => {
      delete this.loadingElements[data];
      this.checkLoaded(); // force computed
    });
  },
  mounted() {
    //@ts-ignore
    this.$root.$confirm = this.$refs.confirm.open;
  },
  methods: {
    async acceptTerms() {
      try {
        await axios.get('/api/termsaccepted/' + this.$store.state.userdata.data.id);
        this.$store.dispatch('userdata', this.$store.state.userdata.data);
      } catch (err) {
        this.$root.$confirm({
          title: this.$t('error.termsaccepted.title').toString(),
          message: this.$t('error.termsaccepted.text').toString(),
          okonly: true,
          appearance: 'success'
        });
      }
    },
    showOverlay(content) {
      this.overlayHtml = content;
      this.overlayTrigger = !this.overlayTrigger;
    },
    dialogClose() {
      this.dialogEdit = false;
    },
    langChoosen(event) {
      this.$router.push({
        path: this.$router.currentRoute.fullPath.replace(/^\/\D\D\//, '/' + event + '/')
      });
    },
    cookieStatus(status) {
      if (status === 'accept') {
        this.cookiesAccepted = true;
      }
    },
    switchBarrierefrei() {
      const router = this.$router;
      const currentRoute = this.$router.currentRoute;
      const query = currentRoute.query;
      const newQuery = Object.assign({}, query);
      // https://forum.vuejs.org/t/how-to-remove-one-query-string-from-url/39176/2
      newQuery.barrierefrei = query.barrierefrei ? undefined : 'true';
      const name = currentRoute.name;
      router
        .replace({
          name,
          params: currentRoute.params,
          query: newQuery
        })
        .catch(() => {});
    },
    checkLoaded() {
      if (Object.keys(this.loadingElements).length === 0) {
        this.$store.dispatch('loading', false);
      }
    },
    restoreOriginalExtent() {
      this.$map.getView().fit(noeExtent, {
        padding: [30, 30, 30, 30]
      });
    }
  }
};
</script>

<style>
html,
body {
  margin: 0px;
  padding: 0px;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
}

.row-pointer {
  cursor: pointer;
}

.shadowy {
  box-shadow: 1px 2px 6px 2px rgba(0, 0, 0, 0.5);
}

.myAppBar .v-toolbar__content {
  padding: 0px !important;
}

.myAppBar.v-app-bar.v-app-bar--fixed {
  z-index: 10;
}

.cookie__floating__content {
  max-height: 400px !important;
}

.langSelect {
  width: 55px !important;
  max-width: 55px !important;
}

.langSelect.v-input {
  font-size: 0.875rem;
}
/* TINYMCE NOTIFICATION HACK */
.tox-notifications-container {
  display: none !important;
}

.backBlue {
  background-color: rgba(0, 90, 154) !important;
}

.langSelect.theme--dark.v-text-field--solo .v-input__control .v-input__slot {
  background: none;
  background-color: rgba(0, 90, 154) !important;
  box-shadow: none;
  -webkit-box-shadow: none;
}
.langSelect.theme--dark.v-text-field .v-input__control .v-input__slot::before {
  border: none !important;
}
.langSelect.theme--dark.v-text-field .v-input__control .v-input__slot::after {
  border: none !important;
}

.mce-content-body {
  border: 1px dotted #aaa;
  padding: 5px;
}

.responsiveX {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.responsiveX.topMed {
  margin-top: 30px;
}
.responsiveX.topMed span {
  display: inline;
}
.responsiveX.topMed a {
  display: inline;
}

.responsiveX.topLarge {
  margin-top: 45px;
}

@media only screen and (max-width: 1500px) {
  .responsiveX {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .responsiveX.topMed {
    margin-top: 25px;
  }
  .responsiveX.topLarge {
    margin-top: 40px;
  }
}
@media only screen and (max-width: 1300px) {
  .responsiveX {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
  .responsiveX.topMed {
    margin-top: 20px;
  }
  .responsiveX.topLarge {
    margin-top: 35px;
  }
}
@media only screen and (max-width: 1000px) {
  .responsiveX {
    width: calc(100% - 10px);
    margin-left: auto;
    margin-right: auto;
  }
  .responsiveX.topMed {
    margin-top: 5px;
  }
  .responsiveX.topLarge {
    margin-top: 15px;
  }
}

.responsiveHeight {
  height: 400px;
  min-height: 400px !important;
  max-height: 400px !important;
}
.responsiveHeight.withControls {
  height: 440px;
  min-height: 440px !important;
  max-height: 440px !important;
}
.responsiveHeight.max250 {
  height: 250px;
  min-height: 250px !important;
  max-height: 250px !important;
}

@media screen and (max-width: 1000px), screen and (max-height: 700px) {
  .responsiveHeight {
    height: 250px;
    min-height: 250px !important;
    max-height: 250px !important;
  }
  .responsiveHeight.withControls {
    height: 290px;
    min-height: 290px !important;
    max-height: 290px !important;
  }
}

.scrollerPage {
  height: calc((var(--vh, 1vh) * 100) - 88px);
  overflow: auto;
}
.scrollerPage.topReduced {
  height: calc((var(--vh, 1vh) * 100) - 118px);
  margin-top: 30px;
}
.ol-zoom.ol-unselectable.ol-control {
  left: auto;
  top: auto;
  right: 10px;
  top: 10px;
}
</style>
<style scoped>
.whiteLogoParking {
  cursor: pointer;
  margin-left: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  width: 62px;
  height: 60px;
  padding-left: 1px;
  padding-right: 1px;
  text-align: right;
}

.v-btn--active {
  border-radius: 0px;
  border-bottom: 1px solid rgb(255, 214, 0) !important;
}

.v-btn--active.mobile {
  border-bottom: none !important;
  color: rgb(255, 214, 0) !important;
}
.v-btn.mobile {
  height: 50px;
  border-top: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.theme--dark.v-btn--active:hover::before,
.theme--dark.v-btn--active::before {
  opacity: 0;
}
.v-btn:not(.v-btn--round).v-size--default {
  padding: 0px;
  margin-right: 25px;
}
.mycompass {
  padding: 4px !important;
  border: 1px solid rgba(255, 255, 255, 0.5) !important;
}
.mycompass.mobile {
  padding: 0px;
  border: none !important;
}

.claim {
  height: 60px;
  padding-left: 10px;
  padding-top: 25px;
}
.claim div {
  color: #fff;
  font-size: 13px;
  font-weight: bold;
  line-height: 14px;
}
.claimimage {
  margin-left: 5px;
  margin-top: 5px;
}

.claimimage img {
  height: 60px;
  width: auto;
}
.v-main.mainPage {
  min-height: calc(var(--vh, 1vh) * 100 - 28px) !important;
  max-height: calc(var(--vh, 1vh) * 100 - 28px) !important;
}

.v-main.mainPage.admin {
  min-height: calc(var(--vh, 1vh) * 100) !important;
  max-height: calc(var(--vh, 1vh) * 100) !important;
}

.mainPage {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 30%,
    rgba(139, 171, 210, 0.5) 60%,
    rgba(139, 171, 210, 1) 100%
  );
}
.mainPageFormer {
  background: linear-gradient(
    180deg,
    rgba(221, 230, 243, 1) 0%,
    rgba(180, 198, 226, 1) 60%,
    rgba(139, 171, 210, 1) 100%
  );
}

.myAccountMobile {
  border-top: 1px solid white;
  height: 0px;
  overflow: hidden;
}
.myAccountMobile.opened {
  height: auto;
  overflow: visible;
}

.navigationDrawer {
  z-index: 6 !important;
}

.shownAboveMobile {
  display: flex;
}
.hiddenAboveMobile {
  display: none;
}
@media only screen and (max-width: 1140px) {
  .shownAboveMobile {
    display: none;
  }
  .hiddenAboveMobile {
    display: flex;
  }
}
</style>
