<template>
  <v-overlay align="center" :value="isLoading" dark z-index="999999" class="text-h3">
    <div class="text-center ma-12">
      <v-progress-circular indeterminate rotate="0" size="100" width="14" color="#FFF" />
    </div>
    <div
      class="text-center text-h3"
      :class="{
        hidden: !showMessage
      }"
    >
      <span class="text-h4">
        {{ message || $t('dialog.loadingMessage') }}
      </span>
    </div>
  </v-overlay>
</template>

<script>
let timeOutKey;

export default {
  name: 'LoadingScreen',
  data() {
    return {
      showMessage: false
    };
  },
  props: {
    isLoading: {
      type: Boolean,
      default: () => false
    },
    message: {
      type: String,
      default: () => ''
    }
  },
  watch: {
    isLoading(value) {
      if (value) {
        timeOutKey = setTimeout(() => {
          // after delay, check again if still loading.
          // if yes, display the loading message.
          if (this.isLoading) {
            this.showMessage = true;
          }
        }, 4000);
      } else {
        this.showMessage = false;
        if (timeOutKey) {
          clearTimeout(timeOutKey);
          timeOutKey = undefined;
        }
      }
    }
  }
};
</script>
<style scoped>
.hidden {
  visibility: hidden;
}
</style>
