<template>
  <v-dialog v-model="dialog" max-width="600px" overlay-opacity="0.6" elevation="8" persistent @keydown.esc="cancel">
    <v-card>
      <v-toolbar dark :color="appearance === 'success' ? 'rgb(0, 90, 154)' : 'red darken-3 text-none'" dense flat>
        <v-toolbar-title dark>
          {{ title }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text
        v-show="!!message"
        class="pa-4 text--primary text-body-1 font-weight-regular"
        v-html="message"
      ></v-card-text>
      <v-card-actions class="pt-0">
        <v-row no-gutters>
          <v-col cols="4"
            ><v-btn v-if="!okonly" block dark color="grey darken-1" @click.native="cancel">
              {{ $t('button.cancel') }}
            </v-btn></v-col
          ><v-col cols="4"></v-col>
          <v-col cols="4"
            ><v-btn block dark color="rgb(0, 90, 154)" @click.native="agree">
              {{ $t('button.ok') }}
            </v-btn></v-col
          >
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// warning component source:
// https://gist.github.com/eolant/ba0f8a5c9135d1a146e1db575276177d

/**
 * @typedef {Object} ConfirmOptions
 * @property {string} title confirm title
 * @property {string} message confirm message
 * @property {(false|boolean)=} okonly optional switch for non cancelable messages
 * @property {(''|'success')=} appearance optional confirm type, handling appearance. 'success' for blue appearance
 */

/**
 * Confirm-Component
 */
export default {
  data: () => ({
    title: '',
    dialog: false,
    resolve: null,
    appearance: '',
    reject: null,
    message: null,
    okonly: false
  }),
  methods: {
    /**
     * @param {ConfirmOptions} args
     * @returns {Promise}
     */
    open(args) {
      this.dialog = true;
      this.title = args.title;
      this.message = args.message;
      this.okonly = args.okonly || false;
      this.appearance = args.appearance || '';
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    }
  }
};
</script>
